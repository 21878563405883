<router-outlet>
<simple-notifications></simple-notifications>
<ng-template #toastTemplate let-title="title" let-indicator="indicator" let-message="message" let-icon="icon">
    <div class="toast" role="alert" aria-live="assertive" aria-atomic="true" style="opacity: 1; z-index: 900000">
        <div class="toast-header">
          <div [class]="'toast-indicator ' + 'toast-' + indicator"></div>
          <strong class="mr-auto">{{title}}</strong>
          <i [class]="icon"></i>
          <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="toast-body">
          {{message}}
        </div>
      </div>
</ng-template>
</router-outlet>