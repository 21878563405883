<div className="container-fluid">
    <div className="row">
        <app-header [showSidebarToggle]="false" [showHomeButton]="false"></app-header>
    </div>
</div>

<div class="container-fluid top-step">
    <div class="row">
      <div class="col-12">
        <h3 class="page-title">Список заведений</h3>
        <h6 class="page-title-second-text">
          Для управления, выберите одно из Ваших заведений или создайте новое 🔑
        </h6>
      </div>
    </div>
    <hr>
    <app-page-loader [show]="loader"></app-page-loader>
    <div class="row" *ngIf="!loader">
      <div class="col-sm-12 col-md-3 mt-3" *ngFor="let company of companies" (click)="onCompanyClick(company)">
        <div class="card shadow">
          <div class="left-part">
            <span class="material-icons-outlined left-part-icon">store</span>
          </div>
          <div class="right-part">
            <div class="content">
              <h5>{{company.name}}</h5>
              <p>{{company.address}}</p>
            </div>
            <div class="footer">
              <button mdbBtn color="indigo" size="sm" class="company-btn" mdbWavesEffect>Выбрать</button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-3 d-flex justify-content-center align-items-center mt-3">
        <div class="new-company-card" (click)="createCompanyClick()">
          <div class="new-company-card-left">
            <span class="material-icons-outlined">add_business</span>
          </div>
          <div class="new-company-card-right">
            <span>+ Добавить заведение</span>
          </div>
        </div>
      </div>
    </div>
</div>

<ng-template #createCompanyModal>
  <div class="container-fluid">
    <form [formGroup]="createForm" (ngSubmit)="createSubmit()">
      <ng-container *ngIf="!modalLoader">
          <div class="row">
              <div class="col-12 mt-2">
                  <label for="name">Название</label>
                  <input type="text" formControlName="name" placeholder="Введите название" id="name" class="form-control">
                  <small class="invalid" *ngIf="createForm.get('name').invalid && submitted">Заполните это поле</small>
              </div>
              <div class="col-12 mt-2">
                  <label for="address">Адресс</label>
                  <input type="address" formControlName="address" placeholder="Введите адресс" id="address" class="form-control">
                  <small class="invalid" *ngIf="createForm.get('address').invalid && submitted">Заполните это поле</small>
              </div>
          </div>
      </ng-container>

      <div class="row mt-2">
          <div class="col-12 d-flex justify-content-end">
              <div class="spinner-border spinner-border-sm" role="status" *ngIf="modalLoader">
                  <span class="sr-only">Loading...</span>
              </div>
              <button mdbBtn size="sm" color="success" type="submit"
                [disabled]="modalLoader" *ngIf="!isEdit">Создать</button>

              <button mdbBtn size="sm" color="primary" type="button" (click)="saveEmployeeChanges()"
                [disabled]="modalLoader" *ngIf="isEdit">Сохранить изменения</button>
          </div>
      </div>
    </form>
  </div>
</ng-template>