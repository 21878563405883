
<div class="container-fluid">

  <div class="row">
    <div class="col-12">
      <h3 class="page-title">Аналитика</h3>
      <h6 class="page-title-second-text">
        С возвращением, {{currentUser?.name || ''}} 👋
      </h6>
    </div>
  </div>

  <hr>

  <div class="row">
    <div class="col-md-3 col-lg-3 col-sm-12">
      <app-statistic-card [iconName]="'event'" [reversColor]="true"
        [value]="currentDate" [isDate]="true" [title]="'Сегодня'"></app-statistic-card>
    </div>

    <div class="col-md-3 col-lg-3 col-sm-12">
      <app-statistic-card [iconName]="'monetization_on'" [percentage]="currentStatistic.revenue.percent"
        [value]="'₴ ' + currentStatistic.revenue.value" [title]="'Выручка'"></app-statistic-card>
    </div>

    <div class="col-md-3 col-lg-3 col-sm-12">
      <app-statistic-card [iconName]="'account_balance_wallet'" [percentage]="currentStatistic.profit.percent"
        [value]="'₴ ' + currentStatistic.profit.value" [title]="'Марж. прибыль'"></app-statistic-card>
    </div>

    <div class="col-md-3 col-lg-3 col-sm-12">
      <app-statistic-card [iconName]="'receipt_long'" [percentage]="currentStatistic.receipts.percent"
        [value]="currentStatistic.receipts.value + ' шт.'" [title]="'Чеки'"></app-statistic-card>
    </div>
  </div>

  <hr>
  <div class="row mt-3">
    <div class="col-12">
<!--      <h4 class="page-sub-title">За выбранный месяц</h4>-->
      <div class="form-inline">
        <h6 class="page-title-second-text mr-2">За выбранный месяц</h6>
        <input type="month" [value]="currentDailyDate | date:'yyyy-MM'" class="form-control ml-3"
               (change)="onDailyDateChange($event)">
      </div>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-md-8 col-lg-8 col-sm-12">
      <div class="card shadow">
        <span class="chart-title">Ежедневный отчет</span>
        <app-daily-chart [dataSource]="dailyData"></app-daily-chart>
      </div>
    </div>
    <div class="col-md-4 col-lg-4 col-sm-12 mt-sm-3">
      <div class="card shadow">
        <span class="chart-title">Финансы</span>
        <app-finance-chart [financesData]="financeData"></app-finance-chart>
        <div class="horizontal-scroll">
          <table mdbTable hover="true" [small]="true">
            <thead>
            <tr>
              <th scope="col">Название</th>
              <th scope="col">Значение</th>
              <th scope="col">Разница</th>
            </tr>
            </thead>
            <tbody>
            <tr mdbTableCol>
              <td class="finance-title-td">
                <span class="material-icons-outlined fin-icon" style="color: #4782da">donut_large</span>
                Доход
              </td>
              <td>₴ {{financeData.income.value}}</td>
              <td class="finance-chart-value"
                  [ngStyle]="{color: financeData.income.difference < 0 ? '#F4436B' : '#66BB6A'}">
                {{(financeData.income.difference > 0 ? '+' : '') + financeData.income.difference}}%</td>
            </tr>
            <tr mdbTableCol>
              <td class="finance-title-td">
                <span class="material-icons-outlined fin-icon" style="color: #f44336">donut_large</span>
                Расходы
              </td>
              <td>₴ {{financeData.costs.value}}</td>
              <td class="finance-chart-value"
                  [ngStyle]="{color: financeData.costs.difference < 0 ? '#F4436B' : '#66BB6A'}">
                {{(financeData.costs.difference > 0 ? '+' : '') + financeData.costs.difference}}%</td>
            </tr>
<!--            <tr mdbTableCol>-->
<!--              <td class="finance-title-td">-->
<!--                <span class="material-icons-outlined fin-icon" style="color: #E5E5E5">donut_large</span>-->
<!--                Средний чек-->
<!--              </td>-->
<!--              <td>₴ {{financeData.averageCheck.value}}</td>-->
<!--              <td class="finance-chart-value"-->
<!--                  [ngStyle]="{color: financeData.averageCheck.difference < 0 ? '#F4436B' : '#66BB6A'}">-->
<!--                {{(financeData.averageCheck.difference > 0 ? '+' : '') + financeData.averageCheck.difference}}%</td>-->
<!--            </tr>-->
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-md-4 col-lg-4 col-sm-1 d-flex justify-content-center align-items-center">
      <span class="material-icons-outlined big-icon">supervisor_account</span>
    </div>
    <div class="col-md-8 col-lg-8 col-sm-12">
      <div class="card shadow">
        <span class="chart-title">График посещаемости</span>
        <app-attendance-chart [dataSource]="attendanceData"></app-attendance-chart>
      </div>
    </div>
  </div>

  <hr>

  <div class="row">
    <div class="col-12">
      <h4 class="page-sub-title">Ежемесячный отчет </h4>
      <select [(ngModel)]="yearStatisticMode" class="form-control mb-2" style="width: 20%">
        <option value="average">В среднем за год</option>
        <option value="sum">В сумме за год</option>
      </select>
<!--      <p style="margin-left: 15px">В среднем за год</p>-->
    </div>
    <div class="col-md-3 col-lg-3 col-sm-12 mt-3">
      <div class="row">
        <div class="col-12">
          <div class="card monthly-card shadow">
            <div class="monthly-card-icon" [ngStyle]="{color: '#11ca28', 'border-color': '#11ca28'}">
              <span class="material-icons">attach_money</span>
            </div>
            <div class="monthly-card-content">
              <span>Выручка</span>
              <h5>₴ {{monthlyAverage.revenue}}</h5>
            </div>
          </div>
        </div>
        <div class="col-12 mt-3">
          <div class="card monthly-card shadow">
            <div class="monthly-card-icon" [ngStyle]="{color: '#11a8ca', 'border-color': '#11a8ca'}">
              <span class="material-icons">timeline</span>
            </div>
            <div class="monthly-card-content">
              <span>Марж. прибыль</span>
              <h5>₴ {{monthlyAverage.profit}}</h5>
            </div>
          </div>
        </div>
        <div class="col-12 mt-3">
          <div class="card monthly-card shadow">
            <div class="monthly-card-icon" [ngStyle]="{color: '#ffbc24', 'border-color': '#ffbc24'}">
              <span class="material-icons">how_to_reg</span>
            </div>
            <div class="monthly-card-content">
              <span>Посещаемость</span>
              <h5>{{monthlyAverage.receipts}}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-9 col-sm-12 mt-3">
      <div class="card shadow">
        <select class="form-control select-year" [value]="currentYear" (change)="onMonthlyYearChange($event)">
          <option value="2021">2021</option>
          <option value="2022">2022</option>
          <option value="2023">2023</option>
          <option value="2024">2024</option>
          <option value="2025">2025</option>
          <option value="2026">2026</option>
          <option value="2027">2027</option>
          <option value="2028">2028</option>
          <option value="2029">2029</option>
          <option value="2030">2030</option>
        </select>
        <app-monthly-chart [dataSource]="monthlyData"></app-monthly-chart>
      </div>
    </div>
  </div>

  <!-- <div class="row mt-2">
    <div class="col-12">
      <h4 class="page-sub-title">Популярные товары </h4>
    </div>
    <div class="col-1"></div>
    <div class="col-10">
      <div class="card shadow p-4">
        <table mdbTable hover="true">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Продукт</th>
              <th scope="col">Кол-во заказов</th>
              <th scope="col">Выручка</th>
            </tr>
          </thead>
          <tbody>
            <tr mdbTableCol *ngFor="let el of [1,2,3,4,5,6]; let i = index">
              <th scope="row">{{el}}</th>
              <td>Product {{el}}</td>
              <td>{{(el + 25) * 2}} шт.</td>
              <td>$ {{el + 500 * 2}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-1"></div>
  </div> -->
</div>
