<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <h3 class="page-title">Новая поставка</h3>
            <h6 class="page-title-second-text">
                Поставка ингредиентов и товаров
                <span style="font-size: 20px"> 🚚</span>
            </h6>
        </div>
    </div>
    <hr>
    <app-page-loader [show]="loader"></app-page-loader>
    <form [formGroup]="createForm" (ngSubmit)="onSubmit()" *ngIf="!loader">

        <div class="row">
            <div class="col-md-9 col-sm-12">
                <div class="card shadow p-3 ml-2 mb-4">
                    <div class="row">
                        <div class="col-md-6 col-sm-12">
                            <label class="mt-3">Поставщик</label>
                            <select class="form-control" formControlName="supplier">
                                <option [ngValue]="null">Выберите поставщика</option>
                                <option [value]="supplier.id" *ngFor="let supplier of suppliers">{{supplier.name}}</option>
                            </select>
                            <small class="invalid" *ngIf="submitted && createForm.get('supplier').invalid">Выберите поставщика</small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-sm-12">
                            <label class="mt-3">Счет для списания средств</label>
                            <select class="form-control" formControlName="account">
                                <option [ngValue]="null">Выберите счет</option>
                                <option [value]="account.id" *ngFor="let account of accounts">{{account.title}}</option>
                            </select>
                            <small class="invalid" *ngIf="submitted && createForm.get('account').invalid">Выберите счет</small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-sm-12">
                            <label class="mt-3">Комментарий</label>
                            <textarea formControlName="comment" cols="20" rows="5" placeholder="Введите комментарий к поставке"
                                class="form-control"></textarea>
                        </div>
                    </div>

                    <div class="ingredients-container mt-3 p-3">
                        
                        <table mdbTable bordered="true" striped="true" class="ing-table">
                            <thead>
                              <tr>
                                <th scope="col" style="width: 25%">Ингредиент</th>
                                <th scope="col" style="width: 13%">Фасовка</th>
                                <th scope="col" style="width: 18%">Количество</th>
                                <th scope="col" style="width: 6%"></th>
                                <th scope="col" style="width: 15%">Цена за ед.</th>
                                <th scope="col" style="width: 6%"></th>
                                <th scope="col" style="width: 15%">Всего</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngIf="!deliveryRows.length">
                                  <td colspan="8" style="text-align: center"><span>Добавьте поставку</span></td>
                              </tr>
                              <tr mdbTableCol *ngFor="let item of deliveryRows; let i = index">
                                <td>
                                    <select class="form-control" [ngModelOptions]="{standalone: true}" 
                                        [(ngModel)]="item.ingredientId" (change)="onSelectIngredient(item)">
                                        <option [ngValue]="null">Выберите ингредиент</option>
                                        <option *ngFor="let ing of ingredients | ingredientsFilter:selectedIngredients:item.ingredientId" 
                                            [value]="ing.id">{{ing.title}}</option>
                                    </select>
                                </td>
                                <td>{{item.unit ? item.unit : '-'}}</td>
                                <td>
                                    <input type="number" min="0" class="form-control" [ngModelOptions]="{standalone: true}"
                                        [(ngModel)]="item.quantity" placeholder="Кол-во" (input)="onChangePriceOrQuantity($event, i, false)">
                                </td>
                                <td class="icon-cell"><i class="fas fa-times"></i></td>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <span class="mr-2">₴</span>
                                        <input type="number" min="0" step=".01" class="form-control" [ngModelOptions]="{standalone: true}"
                                            [(ngModel)]="item.price" placeholder="Цена" (input)="onChangePriceOrQuantity($event, i)">
                                    </div>
                                </td>
                                <td class="icon-cell"><i class="fas fa-equals"></i></td>
                                <td style="text-align: center">
                                    <div class="d-flex align-items-center">
                                        <span class="mr-2">₴</span>
                                        <input type="number" min="0" step=".01" class="form-control" [ngModelOptions]="{standalone: true}"
                                            [(ngModel)]="item.sum" placeholder="Сумма" (input)="onChangeSum($event, i)">
                                    </div>
                                </td>
                                <td><span class="material-icons-outlined remove-delivery-btn" (click)="removeDeliveryItem(i)"
                                    title="Отменить эту поставку">cancel_presentation</span></td>
                              </tr>
                            </tbody>
                        </table>
                        <small class="invalid" *ngIf="submitted && !deliveriesIsValid">Некоторые поля заполнены не верно</small>
                        <small class="invalid" *ngIf="submitted && !deliveryRows.length">Нужно добавить хотя бы одну поставку</small>
                    </div>
                    <div class="d-flex justify-content-between">
                        <button mdbBtn size="sm" color="primary" type="button" 
                            class="ml-3" (click)="addDeliveryItem()">
                            <div class="btn-content">
                                <span class="material-icons-outlined icon">add</span> Добавить еще
                            </div>
                        </button>

                        <div *ngIf="deliveryRows.length" class="mr-3">
                            <label class="mt-2"><b>Итого:</b></label>
                            <span class="ml-2">₴ {{totalSum}}</span>
                        </div>
                    </div>

                    <div class="d-flex justify-content-center">
                        <button type="submit" mdbBtn color="success" class="mt-4" size="md">
                            Оформить поставку
                        </button>
                    </div>

                </div>
            </div>
        </div>
    </form>
</div>