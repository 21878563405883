<div class="container-fluid">
    <div class="row">
        <div class="col-12">
          <h3 class="page-title">Категории товаров</h3>
        </div>

        <div class="col-12">
            <button mdbBtn size="sm" color="success" [disabled]="loader"
                (click)="onCreateCategoryClick()">
                <div class="btn-content">
                    <span class="material-icons-outlined icon">add</span> Создать категорию
                </div>
            </button>
        </div>
    </div>

    <hr>
    <app-page-loader [show]="loader"></app-page-loader>
    <app-no-data icon="all_inbox" title="Список категорий пуст" [show]="!loader && !categories.length"></app-no-data>

    <ng-container *ngIf="!loader && categories.length">
        <div class="row mt-2">
            <div class="col-md-4 col-sm-12">
                <div class="d-flex">
                    <span class="material-icons-outlined mt-2 mr-2">search</span>
                    <input type="text" style="width: 100%" placeholder="Поиск" [(ngModel)]="searchText" class="form-control mb-2">
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-12">
                <div class="card shadow p-2 horizontal-scroll">
                    <table mdbTable bordered="true" striped="true" class="custom-tbl">
                        <thead>
                          <tr>
                            <th scope="col" class="th-icon">
                                <span class="material-icons-outlined">color_lens</span>
                            </th>
                            <th scope="col">Название</th>
                            <th scope="col">Статус</th>
                            <th scope="col" class="th-icon">
                                <span class="material-icons-outlined">handyman</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr mdbTableCol *ngFor="let category of categories | search:searchText:'title'">
                            <td style="width: 10%">
                                <div class="d-flex justify-content-center">
                                    <div class="color-point shadow" [ngStyle]="{background: category.color}"></div>
                                </div>
                            </td>
                            <td>{{category.title}}</td>
                            <td>
                                <mdb-badge color="pink" *ngIf="!category.published">Скрыто</mdb-badge>
                                <mdb-badge success="true" *ngIf="category.published">Доступно</mdb-badge>
                            </td>
                            <td style="width: 30%">
                                <button mdbBtn size="sm" color="indigo" (click)="editClick(category)">
                                    <div class="btn-content">
                                        <span class="material-icons-outlined icon">edit</span> Изменить
                                    </div>
                                </button>
                                <button mdbBtn size="sm" color="danger"
                                    [disabled]="!category.allowToRemove"
                                    (click)="removeClick(category)" [title]="!category.allowToRemove ? 'Удаление запрещено так как эту категорию используют некоторые продукты' : ''">
                                    <div class="btn-content">
                                        <span class="material-icons-outlined icon">delete</span> Удалить
                                    </div>
                                </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                </div>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #createModal>

    <div class="container-fluid">
        <form [formGroup]="createForm" (ngSubmit)="createSubmit()">
            <ng-container *ngIf="!modalLoader">
                <div class="row">
                    <div class="col-12 mt-2">
                        <label for="title">Название</label>
                        <input type="text" formControlName="title" placeholder="Введите название" id="title" class="form-control">
                        <small class="invalid" *ngIf="createForm.get('title').invalid && submitted">Заполните это поле</small>
                    </div>
                    <div class="col-12 mt-2">
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" name="published" value="1" class="custom-control-input" id="statusTrue" formControlName="published" mdbInput>
                            <label class="custom-control-label" for="statusTrue">Доступно</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" name="published" value="0" class="custom-control-input" id="statusFalse" formControlName="published" mdbInput>
                            <label class="custom-control-label" for="statusFalse">Скрыто</label>
                        </div>
                    </div>
                    <div class="col-12 mt-3">
                        <label for="color">Цвет категории</label>
                        <app-color-picker [attr.id]="'color'" [colors]="colors" [selectedColor]="createForm.value.color" 
                            (colorChanged)="categoryColorChange($event)"></app-color-picker>
                        <small class="invalid" *ngIf="createForm.get('color').invalid && submitted">Выберите цвет категории</small>
                    </div>
                </div>
            </ng-container>

            <div class="row mt-2">
                <div class="col-12 d-flex justify-content-end">
                    <div class="spinner-border spinner-border-sm" role="status" *ngIf="modalLoader">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <button mdbBtn size="sm" color="success" type="submit"
                     [disabled]="modalLoader" *ngIf="!isEdit">Создать</button>

                    <button mdbBtn size="sm" color="primary" type="button" (click)="saveCategoryChanges()"
                     [disabled]="modalLoader" *ngIf="isEdit">Сохранить изменения</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #removeModal>
    <div class="container-fluid">

        <div class="row">
            <div class="col-12">
                <p>
                    Вы уверены, что хотите удалить выбранную категорию ?
                </p>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-12 d-flex justify-content-end">
                <div class="spinner-border spinner-border-sm" role="status" *ngIf="modalLoader">
                    <span class="sr-only">Loading...</span>
                </div>
                <button mdbBtn size="sm" color="danger" (click)="removeCategory()"
                 [disabled]="modalLoader" [disabled]="modalLoader">Подтвердить удаление</button>
            </div>
        </div>
    </div>
</ng-template>